<template>
  <b-card>
    <div class="d-flex justify-content-between flex-wrap mt-1">
      <h4>{{ $t('members.edit.custom-fields.label') }}</h4>
      <div class="d-flex">
        <b-button variant="primary" class="ml-1" :disabled="!allCustomFields || allCustomFields.length <= 0" @click="addCustomField()">
          {{ $t('form.actions.save') }}
        </b-button>
      </div>
    </div>

    <b-form>
      <b-spinner v-if="isLoading" type="grow" small class="d-block mx-auto text-primary" />
      <template v-else-if="!allCustomFields || allCustomFields.length <= 0">
        {{ $t('members.edit.no-items') }}
      </template>
      <b-form-group v-else label-for="customFields-name">
        <div 
          v-for="(field, index) in allCustomFields" 
          :key="index" 
          class="font-weight-bold mb-2"
        >
          <template>
            <!-- Field name -->
            {{ getFieldName(field) }}
            <template v-if="field.required">
              *
            </template>
            <b-form-input
              v-model="customFieldsResponses[field.key]"
              v-if ="field.type !== 'timestamp' && field.type !== 'date' && field.type !== 'boolean'"
              :type="getInputType(field.type)"
              :state="isValidNumber(field.type, field.key)"
              class="mt-50"
            />
            <b-form-datepicker
              v-model="customFieldsResponses[field.key]"
              v-else-if ="field.type === 'timestamp' || field.type == 'date'"
              class="mt-50"
              :locale="locale"
            />
            <b-form-checkbox
              v-else
              v-model="customFieldsResponses[field.key]"
              switch
              class="mt-50"
            />
            <b-form-invalid-feedback v-if="(field.type === 'integer' || field.type === 'decimal') && !isValidNumber(field.type, field.key)" class="text-danger">
              <span v-if="field.type === 'integer'">
                {{ $t('backoffice.custom-fields.error-integer') }}
              </span>
              <span v-if="field.type === 'decimal'">
                {{ $t('backoffice.custom-fields.error-decimal') }}
              </span>
            </b-form-invalid-feedback>
          </template>
        </div>
      </b-form-group>
    </b-form>
  </b-card>
</template>
<script>
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';

export default {
  name: 'CustomFields',
  model: {
    prop: 'visible',
    event: 'update: change',
  },
  mixins: [ToastNotificationsMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    visible: Boolean,
    morphType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      customFieldsResponses: {},
      isLoading: false,
      customFields: [],
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    isDisabled() {
      return !this.isValidNumber;
    },
    cFields() {
      return this.$store.getters.customFields;
    },
    allCustomFields() {
      const propCustomFields = this._props.item?.custom;
      // console.log('propCustomFields', propCustomFields);
      // console.log('this.customFields', this.customFields);
      if (this.customFields && this.customFields.length > 0) {
        // console.log('there are customFields from a dedicated endpoint');
        const communityFields = this.customFields.map((x, index) => {
          if (propCustomFields) {
            propCustomFields.map(item => {
              if (item.key === x.key) {
                this.customFieldsResponses[x.key] = item.value
              }
            });
          }
          return { ...x };
        });
        return [...communityFields];
      } else {
        // console.log('sending customFields from inside the space, in field "custom"');
        const something = propCustomFields.map(item => {
          this.customFieldsResponses[item.key] = item.value
        });
        // console.log('something', something);
      }
      return propCustomFields;
    },
  },
  watch: {
    allCustomFields(value) {
      return value;
    },
  },
  async created() {
    await this.fetchCustomFields();

    //get value from getters
    this.customFields = this.cFields;
  },
  methods: {
    async fetchCustomFields() {
      this.isLoading = true;
      await this.$store.dispatch('fetchCustomFields', { 
        modelType: this.morphType 
      });
      this.isLoading = false;
    },
    async addCustomField() {
      this.$emit('update: change', false);
      let response;
      try {
        response = await this.$store.dispatch('AddCustomFields', {
          modelKey: this.item.key,
          modelType: this.morphType,
          customKey: Object.keys(this.customFieldsResponses),
          customField: Object.values(this.customFieldsResponses),
          noSet: true,
        });
        this.$emit('profile-updated');
        this.$emit('profileUpdated', this.customFieldsResponses);
        this.notifySuccess(this.$t('members.edit.success-message'));
      } catch (error) {
        this.notifyError(this.$t('error-message.general-error'));
      }
      return response;
    },
    translateTranslationTable,
    isSame(item) {
      return item?.map(item=> item === this.morphType).length > 0;
    },
    getInputType(type) {
      const types = {
        string: 'type',
        text: 'type',
        integer: 'number',
        decimal: 'number',
        number: 'number',
      };
      return types[type];
    },
    isValidNumber(type, key) {
      if (this.customFieldsResponses[key]) {
        if (type === 'integer') {
          return this.customFieldsResponses[key] % 1 == 0;
        }
        if (type === 'decimal') {
          return this.customFieldsResponses[key] % 1 != 0;
        }
        return null;
      }
      return null;
    },
    translate(string){
      return translateTranslationTable(this.$store.state.locale.currentLocale, string);
    },
    getFieldName(field){
      // console.log('getFieldName field:', field)
      return this.translate(field.name ?? field.customFieldName);
    }
  },
};
</script>
